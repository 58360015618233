import * as React from "react"

function FaPointer() {

	return (
 
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24px" height="22px" >

<g><path  fill="#6ac9ed" d="M 5.5,5.5 C 9.63679,7.04511 13.6368,8.87845 17.5,11C 13.9123,12.9227 10.2457,14.756 6.5,16.5C 6.16667,16 5.83333,15.5 5.5,15C 6.16667,13.6667 6.83333,12.3333 7.5,11C 6.14391,9.43292 5.47724,7.59959 5.5,5.5 Z"/></g>
</svg>

    );
}

export default FaPointer;