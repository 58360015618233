import * as React from "react"
import { Container, Carousel } from "react-bootstrap" 
import { StaticImage } from "gatsby-plugin-image"

import  FaPointer  from "../../../images/FaPointer";


function About() {
	return (
        
        <div class="about-us">
            <div className="about-overlay"></div>
            <div className="row">
                <div className="col-lg-6"></div>
                <div className="col-lg-6 last">
                    <h1>Who We Are?</h1>
                    <p>January 2015, my wife and I decided to start our own tour business. D-Golden tours has a mandate, and that is to make our guests happy and have an adventure of a lifetime.
                    I am ready to show and share with you what my grandparents taught me. Many of the information that I will share with you is through experience.... <a href="/about-us">Read More</a></p>
                    <p> < FaPointer /> Life time Local Knowledge</p>
                    <p>< FaPointer /> Dependable</p>
                    <p> < FaPointer /> Reliable</p>
                    <p>< FaPointer /> 100% Money Protected</p>
                  
                </div>
        
            </div>
            
         </div>

    );
}

export default About;                           